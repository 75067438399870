.main-image-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #ffffff; /* Beyaz arka plan rengi */
  display: flex; /* Flex kullanarak dikey ve yatay ortalama */
  justify-content: center;
  align-items: center;
  height: auto; /* Yükseklik oranını kaldırıyoruz */
  padding-bottom: 20px; /* Alt tarafa beyaz boşluk ekler */
  border-radius: 8px; /* Hafif köşe yuvarlama (isteğe bağlı) */
}

.main-image-container img {
  width: auto; /* Genişlik orantılı */
  height: 100%; /* Görüntüyü yüksekliğe göre ayarla */
  max-height: 100%;
  object-fit: contain; /* Orijinal oranı koru, taşmaları engelle */
}


img.tooth {
  image-rendering: crisp-edges; /* Chrome için netlik sağlar */
}


.tooth {
  object-fit: contain;
  clip-path: inset(0% 15% 0% 15%); /* Üstten, sağdan, alttan, soldan kırp */
}

.checkbox-container label {
  display: flex;
  align-items: center;
  gap: 8px; /* Checkbox ve metin arasına boşluk */
}

.checkbox-label {
  white-space: nowrap; /* Metnin bölünmesini engeller */
}
